/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "@chakra-ui/react";
import {CodeSandbox} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Example Pattern for Typing Union Type on Change event"), "\n", React.createElement(Box, {
    as: CodeSandbox,
    mb: 6,
    codeSandboxId: "react-typescript-typing-pattern-onchange-usestate-2022-ytlsdm?fontsize=14theme=dark&view=editor=&file=/src/App.tsx"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
